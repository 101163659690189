import React from 'react';
import features from 'data/Saas/features.json';
import featuresRTL from 'data/Saas/features-rtl.json';

const PrivacyPolicy = ({ isServices, rtl }) => {
  const featuresData = rtl ? featuresRTL : features;

  return (
    <section className={`features section-padding style-5 ${isServices ? 'pt-50':'bg-[#ff0001]'}`} data-scroll-index="3">
      <div className="container">
        <div className="row ">
          <div className="col-lg-12">
            {
              isServices ? 
              (
                <div className="section-head text-left mb-60 style-5">
                  <h2 className="mb-20">{ 'Privacy ' } <span>{ 'Policy' }</span></h2>
                  <p>
                  
At Mutual Trust Microfinance Bank Limited (hereinafter referred to as Mutual Trust MFBank Limited), one of our main priorities is the privacy of our visitors.

This Privacy Policy document contains types of information that is collected and recorded by Mutual Trust MFBank Limited and how we use it.

This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Mutual Trust MFBank Limited. This policy does not apply to any information collected offline or via channels other than this website.
</p>
<b>Consent</b>
<br />
<p>
By using our website, you hereby consent to our Privacy Policy and agree to its terms.
</p>
<b>Information we collect</b>
<br />
<p>
The personal information you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

When you register for an Account with us, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
<br />
<b>How we use your information</b>
<br />

We use the information we collect in various ways, including:
<ul>
  <li>Provide, operate, and maintain our website</li>
  <li>Improve, personalize, and expand our website</li>
  <li>Understand and analyze how you use our website</li>
  <li>Develop new products, services, features, and functionality</li>
  <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes
  </li>
  <li>Send you emails</li>
  <li>Find and prevent fraud</li>
  <li>Log Files</li> 
</ul>



<br />
Mutual Trust MFBank Limited follows a standard procedure of using log files for visitors when they visit our website. The information we collect from these log files includes internet protocol (IP) addresses, browser type such as Google Chrome or Mozilla Firefox, Internet Service Provider (ISP), date and time stamps, referring/exit pages, and possibly the number of clicks you have made around the website.

These are not linked to any personally identifiable information. Our purpose in collecting this information is for analysing digital trends, administering the site, tracking visitors’ movement on the website, and gathering basic demographic information.

Advertising Partners Privacy Policies You may consult this list to find the Privacy Policy for each of the advertising partners of Mutual Trust MFBank Limited.

Third-party ad servers or ad networks use technologies like cookies, JavaScript,
 or Web Beacons that are used in their respective advertisements and links that appear on Mutual Trust MFBank Limited, which are sent directly to your browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on our website when you visit.

Note that Mutual Trust MFBank Limited has no access to or control over these cookies that are used by third-party advertisers.
<br />
<b>Third Party Privacy Policies</b><br />

Mutual Trust MFBank Limited’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how you can opt out of certain options.

You can choose to disable cookies through your browser options. To know more detailed information about cookie management with specific web browsers, it can be found on your browser’s respective websites.
<br />
<b> Social Media Platforms</b><br />
Mutual Trust MFBank Limited may interact with registered users of various social media platforms, including Facebook, Twitter, Instagram, and YouTube. Please note that any content you post to such social media platforms (e.g., pictures, information, or opinions), as well as any personal information that you otherwise make available to users (e.g., your profile), is subject to the applicable social media platform’s terms of use and privacy policies. We recommend that you review this information carefully to better understand your rights and obligations regarding such content.

Privacy Rights (Do Not Sell My Personal Information)

Among other rights, you have the right to:

Request that as we collect your data disclose the categories and specific pieces of personal data that we have collected about you.

Request that we should delete any personal data about you that we have collected.

Request that as a bank, we cannot sell your data.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
<br />
<b>Data Protection Rights</b><br />

We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:

The right to access – You have the right to request copies of your data. We may charge you a small fee for this service.

The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you think is incomplete.

The right to erasure – You have the right to request that we erase your data, under certain conditions.

The right to restrict processing – You have the right to request that we restrict the processing of your data under certain conditions.

The right to object to processing – You have the right to object to our processing of your data, under certain conditions.

The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
<br />
<b>Children’s Information</b><br />

Another part of our priority is added protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activities.

Mutual Trust MFBank Limited does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
<br />
<b>Where We Store Your Information</b><br />
All Personal Information you provide to us has been stored on our secure servers as well as secure physical locations and cloud infrastructure (where applicable). The data that we collect from you may be transferred to and stored at a destination outside Nigeria. Whenever your information is transferred to another location, we will take all necessary steps to ensure that your data is handled securely and by this privacy policy.
<br />
<b>Maintaining Accurate Information</b>
<br />
Keeping your account information accurate and up to date is very important. You have access to your account information, which includes your contact information, account balances, transactions, and similar information through various means, such as account statements, SMS Banking, Social Media Banking, and Internet Banking.

If you discover any inaccuracies in your personal information, please promptly notify us, via our branch network or Contact Centre, and provide the required documentary evidence, to enable us to implement the necessary updates or changes.
<br />

<b>Promotional Messages</b><br />
Mutual Trust MFBank Limited may sometimes contact you with products or services that we think may be of interest to you. If you don’t want to receive such promotional materials from us, you can opt-out at any time by sending an email to mtcomms@mutualtrustmfbank.com
<br />
<b>Privacy Policy Changes</b><br />
This policy may be revised on an ad-hoc basis to reflect the legal, regulatory and operating environment and such revised versions will automatically become applicable to you. We will post any revisions we make to our Privacy Policy on this page and such a revised policy becomes effective at the time it is posted. We also encourage you to check this page from time to time for updates to this policy.
<br />
<b>CONTACT</b><br />
If you have additional questions, comments, or require more information about our Privacy Policy, you are welcome to address such to mtcomms@mutualtrustmfbank.com or Navigate to the Contact us Page
                  </p>
                </div>
              )
              :
              (
                <div className="section-head text-center mb-60 style-5">
                  <h2 className="mb-20">Business <span>Services</span></h2>
                  <p>
                  From banking packages to credit products, we cover all your business banking needs. Plus, our wealth management service will help you to build, strengthen and preserve your wealth.
                  </p>
                </div>
              )
            }
          </div>
        </div>
       
      </div>
    </section>
  )
}

export default PrivacyPolicy;